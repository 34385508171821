// action - state management
import * as actionTypes from '../actions/userAction';

export const initialState = {
  token: null,
  company: null,
  user: null
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_TOKEN:
      return {
        ...state,
        token: action.token
      };
    case actionTypes.SET_COMPANY:
      return {
        ...state,
        company: action.company
      };
    case actionTypes.SET_USER:
      return {
        ...state,
        user: action.user
      };

    default:
      return state;
  }
};

export default userReducer;
