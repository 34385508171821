// action - state management
import * as actionTypes from '../actions/assessmentAction';

export const initialState = {
  categories: null
};


const assessmentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CATEGORIES:
      return {
        ...state,
        categories: action.categories
      };
    default:
      return state;
  }
};

export default assessmentsReducer;
