import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
  
//   routing
import Routes from './routes';
  
//defaultTheme
import themes from './themes';
 
//  project imports
import NavigationScroll from './layout/NavigationScroll';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
 
// ==============================|| APP ||============================== //
 
const App = () => {
   const customization = useSelector((state) => state.customization);
    const {pathname} = useLocation();
    const token = localStorage.getItem('auth_refresh_token')
    const navigate = useNavigate();
  useEffect(()=>{
     if(!token && pathname && pathname !== '/login' && pathname !== '/register')
     {
      navigate('/login');
     }
  },[pathname,token,navigate])


  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        <NavigationScroll>
          <Routes />
        </NavigationScroll>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
