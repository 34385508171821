import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import userReducer from './reducers/userReducer';
import assessmentsReducer from './reducers/assessmentReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  customization: customizationReducer,
  user: userReducer,
  assessments: assessmentsReducer,

});

export default reducer;
