// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import { useEffect, useState } from 'react';
import { directusService } from 'services/directus.services';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const getData = async () => {
      var isAdmin = await directusService.isAdmin();
      
      setIsAdmin(isAdmin);
    };
    getData();

  }, []);



  const navItems = menuItem.items.map((item) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} isAdmin={isAdmin} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return <>{navItems}</>;
};

export default MenuList;
