// assets
import { IconDashboard, IconClipboardPlus, IconGitCompare } from '@tabler/icons';
import { directusService } from 'services/directus.services';
import { useEffect } from 'react';


// constant
const icons = { IconDashboard, IconClipboardPlus, IconGitCompare };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //



const dashboard = {
  id: 'dashboard',
  title: 'Dashboard',
  type: 'group',
  children: [
    {
      id: 'assessment',
      title: 'Assessment',
      type: 'item',
      url: '/assessment',
      icon: icons.IconClipboardPlus,
      breadcrumbs: false,
      needAdmin: false,
    },
    {
      id: 'comparer',
      title: 'Comparer',
      type: 'item',
      url: '/comparer',
      icon: icons.IconGitCompare,
      breadcrumbs: false,
      needAdmin: true,
    },
  ]
};

export default dashboard;
