/* eslint-disable no-unused-vars */
import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));
const LoginPage = Loadable(lazy(() => import('views/pages/authentication/authentication/Login')));

// sample page routing
const AssessmentPage = Loadable(lazy(() => import('views/Assessment')));
const StatsPage = Loadable(lazy(() => import('views/Stats')));
const AssessmentContentPage = Loadable(lazy(() => import('views/Categories')));
const QuestionsStepperPage = Loadable(lazy(() => import('views/QuestionsStepper')));
const SamplePage = Loadable(lazy(() => import('views/sample-page')));
const ComparerPage = Loadable(lazy(() => import('views/Comparer')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <DashboardDefault />
    },
    {
      path: 'dashboard',
      children: [
        {
          path: ':id',
          element: <StatsPage />
        },
        {
          path: 'default',
          element: <DashboardDefault />
        }
      ]
    },
    {
      path: 'assessment',
      element: <AssessmentPage />
    },
    {
      path: 'comparer',
      element: <ComparerPage />
    },
    {
      path: 'assessment/:id',
      element: <AssessmentContentPage />
    },
    {
      path: 'assessment/:id/questions',
      element: <QuestionsStepperPage />
    },

    // ---------------------------------
    {
      path: 'utils',
      children: [
        {
          path: 'util-typography',
          element: <UtilsTypography />
        }
      ]
    },
    {
      path: 'utils',
      children: [
        {
          path: 'util-color',
          element: <UtilsColor />
        }
      ]
    },
    {
      path: 'utils',
      children: [
        {
          path: 'util-shadow',
          element: <UtilsShadow />
        }
      ]
    },
    {
      path: 'icons',
      children: [
        {
          path: 'tabler-icons',
          element: <UtilsTablerIcons />
        }
      ]
    },
    {
      path: 'icons',
      children: [
        {
          path: 'material-icons',
          element: <UtilsMaterialIcons />
        }
      ]
    },
    {
      path: 'sample-page',
      element: <SamplePage />
    }
  ]
};

export default MainRoutes;
