// material-ui
//import { useTheme } from '@mui/material/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

import logo from 'assets/images/innomax-logo.png';
import whiteLogo from 'assets/images/innomax-logo-white.png';

const Logo = ({ variant }) => {
  if (variant === 'white') {
    return <img src={whiteLogo} alt="Berry" width="100" />;
  }

  return <img src={logo} alt="Berry" width="100" />;
};

export default Logo;
